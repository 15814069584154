import React from "react"
import Scrollspy from "react-scrollspy"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Footer from "../components/footer"
import Recommendations from "../components/recommendations"
import Top from "../components/top"
import { Link } from "gatsby"
import Back from "../components/back"
import Preview from '../components/Preview'

const Trivago = props => (
    <Layout>
        
<Preview/>
        <Hero
            title="Trivago"
            summary="Helping people explore and find the best travel destinations 🏝"
            cover="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612543415/trivago/cover.png"
        />

        <div class="grid md:grid-cols-3 px-6 md:px-24">
            <div class="text-left md:w-8/12 hidden md:block">
           
                <div
                    data-sal="slide-right"
                    data-sal-duration="900"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    class="sticky top-32 flex flex-col gap-1.5 ml-2">
                         
                         

                    <p class="container font-semibold text-theme-text py-2 text-sm tracking-wider text-left">
                    OVERVIEW ⤵
          </p>
                    <Scrollspy
                        items={[
                            "section-1",
                            "section-2",
                            "section-3",
                            "section-4",
                            "section-5",
                        ]}
                        class="container text-base ml-8 w-8/12 py-1.5 rounded-sm cursor-pointer"
                        currentClassName="text-main-text container -ml-4 my-1 px-4 w-auto py-1.5 rounded-sm border-orange-600 border-l-4 bg-secondary font-bold">
                        <li class="">
                            <a href="#section-1">About</a>
                        </li>
                        <li class="">
                            <a href="#section-2">Increasing click-through rate</a>
                        </li>
                        <li class="">
                            <a href="#section-3">Improving the mobile experience</a>
                        </li>
                       
                        <li class="">
                            <a href="#section-4">Outcomes and Learnings</a>
                        </li>
                    </Scrollspy>
{/* 
                    <div class="bg-secondary bg-special-500 rounded-sm p-5 my-12">
                        <blockquote class="text-left w-auto"><p>See my skills and experience</p></blockquote>

                        <Link to="https://res.cloudinary.com/dcj89ranh/image/upload/v1641133338/TrustScore/Badara_Olawale_Resume.pdf" target="_blank" data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="310"
                            data-sal-easing="ease" class="transform transition duration-500 ease-in-out bg-main-text py-3 px-5 mt-4 text-secondary shadow-lg rounded-full float-left font-semibold text-sm hover:shadow-2xl hover:opacity-90">View my Resume</Link>


                    </div> */}
                </div>
            </div>

            <div class="divide-y divide-divider md:col-span-2 grid grid-cols-1 gap-8 md:gap-12">
                <section id="section-1" class="current">
                    <p class="text-2xl font-semibold text-left pt-8 pb-4">About</p>
                    <div class="subpixel-antialiased md:text-lg md:text-l font-base leading-7 md:leading-8 text-left break-words">
                        <p>
                            I joined trivago in 2020 and during my time there I worked on projects focusing on improving the experience of users of the trivago
                            magazine which serves millions of people in over 10 locales. The goal was to help travelers make better decisions, guiding them down the travel funnel from exploring destinations to booking on the Hotel search side of trivago. 
                            </p>
                        <p>
                            I worked in the marketing design team collaborating with 3 other UI/UX Designers.
            </p>
                    </div>



                </section>

                <section id="section-2" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">
                        Increasing click-through rate
                      </p>
                     
          
                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-4 text-left">
                        The landing page of trivago features a section which provides users with inspirational content relating to travel destinations.
                        The goal was to help users make better travel decisions - providing travel inspiration for their next trip, 
                        experiences to look forward to, hotels available and their ratings.

          </p>


          <p class="text-xl font-semibold text-left pt-8 pb-3">The Challenge</p>
              <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
              Based on quantitative insights, <b>7% Traffic </b>to the trivago magazine was from the trivago landing page (Hotel search). 
              Hence, the challenge to increase the traffic to the magazine from the trivago landing page.
              To explore ideas on increase the click-through rate, the first task was to highlight limitations with the current design.
          </p>
                          <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612563047/trivago/Trivago_Landing.png"
                            class="mx-auto bg-secondary p-1 md:p-8 rounded-t-xl md:w-11/12"
                            alt="trivago-process"
                        />
              <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Limitations with the current implementation
          </p>
          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
              Two main challenges were identified; </p>
          <ul class="list-disc list-inside text-left md:w-10/12">
                                    <li>The first is the limited article categories, only three categories are listed including a fourth category which leads users to the
                                      landing page of the magazine.
                                    </li>
                                    <li>Second is the absense of any visual cue to prompt users to click-through to the magazine.</li>
                                   
                                </ul>

                    <p class="text-xl font-semibold text-left pt-8 pb-3">Exploring solutions</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        One of the ideas was to increase the number of articles (destination categories) listed on the landing page. This idea was visually
                        represented using a carousel so that users can scroll through and see the diversity as well as the value of content on the magazine.
          </p>

          <div class="md:flex gap-2">
                      <div>
                          <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612618245/trivago/Idea-A.png"
                        class="mx-auto rounded-md bg-secondary"
                        alt="img-alt"/>
                      </div>
                        <div>
                             <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612618246/trivago/Idea-B.png"
                        class="mx-auto rounded-md bg-secondary"
                        alt="img-alt"/>
                        </div>
                       
                    </div>

                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Ideas focused on increasing the content available on the landing page
          </p>

          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        The low fidelity designs above show the ideas I explored. Another idea to increase the click-through rate was to include
                        a call to action to provide an entry point to the magazine's landing page. 

                          </p>

          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612568043/trivago/A.png"
                            class="mx-auto bg-secondary p-1 md:p-8 rounded-md md:w-11/12"
                            alt="trivago-process"
                        />
                    <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Mockup of the first idea
          </p>

          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                    To communicate the ideas to stakeholders, I created high-fidelity designs of the initial ideas.
                    The first idea featured more articles in a carousel under tabs. I only made little adjustments to the copy of this section
                    highlighting the number of articles available on the magazine.
                          </p>

          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612568047/trivago/B.png"
                            class="mx-auto bg-secondary p-1 md:p-8 rounded-t-xl md:w-11/12"
                            alt="trivago-process"
                        />
                         <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Mockup of the second idea
          </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-4 text-left break-words">
                        The second idea seemed more aligned towards solving the challenges highlighted at the start as it not only featured more articles 
                        but also provided a clear call to action which leads users to the magazine.
          </p>
          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612568051/trivago/B_Scrolled.png"
                            class="mx-auto bg-secondary p-1 md:p-8 rounded-t-xl md:w-11/12"
                            alt="trivago-process"
                        />
                         <p class="subpixel-antialiased py-0 text-sm font-base leading-7   pb-4 text-center text-gray-300">
                         Mockup of the second idea (highlighting when a user scrolls the carousel)
          </p>


                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        Also, the second idea allowed for the section to stand out better as the first idea was really similar to the section after (the top destinations section)
                        which I felt was a important in higlighting the magazine section on the landing page.
          </p>

                   
                </section>

                <section id="section-3" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Improving the mobile experience</p>
                    <div class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                            In my first weeks, I got to deep dive into insights from Google Analytics and Hotjar which are tools used to gather quantitative data about users
                            of the magazine. I also collaborated with another designer to conduct a mobile UX audit of the entire
                            trivago magazine and provided recommendations to the problems highlighted.
          </p>
          <p class="text-xl font-semibold text-left pt-8 pb-4">Insights from discovery</p>
          <div class="md:flex gap-8">
                        <div class="rounded-sm bg-secondary bg-opacity-10 p-4 flex-1">
                     

                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612715913/trivago/Heatmap.svg"
                        class=""
                        alt="heatmap_icon"
                    />
                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            Heatmaps and session recordings show users are confused about navigation
              </p>
                        </div>

                        <div class="rounded-sm bg-secondary p-4 flex-1">
                        
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612715913/trivago/Mobile.svg"
                        class=""
                        alt="mobile_icon"
                    />

                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            Mobile has the highest percentage of users
              </p>
                        </div>
                    </div>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                        One of the first problems identified were issues with text accessbility. Low contrast between some text and the body background
                        makes it difficult to read.
                       
          </p>
                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635754/trivago/Audit_1.png"
                        class="mx-auto bg-secondary md:p-8 rounded-t-xl"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Accessibility issues uncovered
          </p>


        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                        Next was the review section within articles. The organization of content in this section could be better structured
                        and the CTA could be from end to end to accomodate users navigating with one hand.
                       
          </p>

          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635766/trivago/Audit_2.png"
                        class="mx-auto bg-secondary md:p-8 rounded-t-xl md:w-10/12"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Structure of the review section and the recommended changes
          </p>


        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                        Another section that could be optimised is the gallery view showing recommended hotels within an article. It takes an average of three clicks
                        to view, scroll and close the gallery modal.
                       
          </p>

          <div class="md:flex">
                 <div>
                          <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635759/trivago/Audit_3.png"
                        class="mx-auto bg-secondary p-0 md:rounded-l-xl"
                        alt="img-alt"/>
                      </div>
                        <div>
                             <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635758/trivago/Audit_4.png"
                        class="mx-auto bg-secondary p-0 md:rounded-r-xl"
                        alt="img-alt"/>
                        </div>
                        
                     </div>
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Viewing images in the gallery section
          </p>
                      
                    </div>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                      The proposed solution (illustrated below) optimises the number of clicks to view images. Also a count of images available is indicated
                      thereby giving users more context.
                       
          </p>

          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612638038/trivago/Solution_1.png"
                        class="mx-auto bg-secondary md:p-8 rounded-t-xl md:w-10/12"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                      Proposed redesign of the gallery section
          </p>
          <p class="text-xl font-semibold text-left pt-8 pb-4">Improving the navigation</p>
          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        Research on how users interact with their mobile phones show certain areas are easy to reach
                        hence, to solve the issue with navigating on mobile, the current navigation was evaluated against
                        standard heatmap on how users interact with their device.
                       
          </p>
          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635761/trivago/Audit_5.png"
                        class="mx-auto bg-secondary p-1 md:p-4 rounded-t-xl"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Benchmarking the navigation based on areas hard and natural to reach for users
          </p>
          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        Also, the number of steps to navigate or search for a destination was also evaluated. In the current implementation,
                        it was quite complex to navigate to a destination.
                       
          </p>
          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612638034/trivago/Audit_6.png"
                        class="mx-auto bg-secondary md:p-4 rounded-t-xl"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Navigating to a destination
          </p>
             
          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                        One idea to fix the two issues highlighted above was to have navigational elements as tabs in the natural to reach
                        area (visually represnted below).
                       
          </p>
          <img src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612635780/trivago/Solution.png"
                        class="mx-auto bg-secondary md:p-4 rounded-t-xl p-0"
                        alt="trivago_alt"
                    />
                     <p class="subpixel-antialiased py-0 text-sm font-base leading-7   pb-4 text-center text-gray-300">
                     Redesign of the navigation focused on placing navigation in natural to reach areas
          </p>
          <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 pt-8 text-left break-words">
                        Presentations were made to the stakeholders to walk them through the ideas and get their feedback. 
                        Unfortunately major design and development changes were halted on the magazine before the proposed
                        changes could be implemented.
                       
          </p>
                </section>

               

                <section id="section-4" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Outcomes and Learnings</p>
                    <div class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                        <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-2 text-left">
                            It was exciting to use insights from Google Analytics and Hotjar session recordings to understand how
                            users navigated through the magazine. I also worked with other team members to plan and execute tests
                            on usertesting.com when a new destination page/template was to be implemented. 
            </p>
            <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-2 text-left">
                            
                            I enjoyed working on the ux audits and ideation sessions as they helped me gain a good grasp of how the magazine operates at
                            the top of the travel funnel moving users down from explorative travelling to eventually booking a hotel on trivago.
            </p>

                       
                    </div>
                </section>
            </div>
        </div>
        <Recommendations projectName="trivago" />
        <Footer />
    </Layout>
)

export default Trivago
